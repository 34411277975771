import { AppFunctionComponent, TextBuilder } from "../types"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SinglePressRelease from "../components/media/single-press-release/single-press-release"
import SEO from "../components/seo.component"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import { PageQueryResult, PressRelease, QueryResult } from "../types"
import { Menu } from "../navigation/types/menu.interface"
import PageMetadata from "../components/seo/page-metadata.interface"

interface Props
  extends PageQueryResult<{
    pressReleases: QueryResult<PressRelease>
    defaultMeta: PageMetadata
    meta?: PageMetadata
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }> {
  pageContext: {
    pressRelease: PressRelease
    path: string
  }
}

const PressReleasePage: AppFunctionComponent<Props> = ({
  pageContext,
  data: {
    meta,
    defaultMeta,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  const { pressRelease } = pageContext

  const pageMeta = meta || defaultMeta

  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      withSmoothScroll
    >
      <SEO {...pageMeta} />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <SinglePressRelease pressRelease={pressRelease} />
    </Layout>
  )
}

export default PressReleasePage

export const query = graphql`
  query PressReleasePage(
    $language: String!
    $locale: String!
    $seoPath: String!
  ) {
    meta: contentfulPageMeta(page: { eq: $seoPath }) {
      ...PageMeta
    }
    defaultMeta: contentfulPageMeta(page: { eq: "about/media" }) {
      ...PageMeta
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
